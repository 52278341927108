import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { TidesStyle } from "../style/tidesStyle"
var moment = require('moment');

const Tides = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [month, setMonth] = useState(moment().month()+1);
  const [heightType, setHeightType] = useState(true);

  const nextMonth = () => {
    setMonth(month + 1);
  }

  const previousMonth = () => {
    setMonth(month - 1);
  }

  const toggleHeightType = () => {
    setHeightType(!heightType)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(process.env.GATSBY_TIDES_API+'&month='+month)
        setData(result.data)
      } catch (error) {
        setIsError(true)
        console.log(error);
      }
        setIsLoading(false)
    }
      fetchData();
  },[month])

  var currMonthName  = moment(moment().year() + '-' + moment(month, 'MM').format('MM') + '-' + "01").format('MMMM') + ' ' + moment().format('YYYY');

  if (isLoading) return <div>Loading tide data</div>;
  if (isError) return <div><b>Tide data is currently unavailable</b></div>;
  return (
    <React.Fragment>
    <div css={TidesStyle}>
      <div className="topbuttongroup">
    {month !== 1 && <button className="tidesbutton" onClick={previousMonth}>Previous Month</button>}
    {month !== 12 && <button className="tidesbuttonright" onClick={nextMonth}>Next Month</button>}
      </div>
      <h2 colSpan="10">
				{currMonthName}
			</h2>
      <span className="previous">One hour has been added for BST when applicable.</span>
      <span className="next">
        <button className="tidesbutton" onClick={toggleHeightType} title="Height in feet" href="">{heightType ? "Height in feet" : "Height in Metres"}</button>
        </span>
      <table summary="Tides at St Helier" className="tide">
		<tbody>
      <tr>
			<th>Date</th>
      <th>High</th>
      {heightType ? <th>Height (M)</th> : <th>Height (Ft)</th>} 
      <th>High</th>
      {heightType ? <th>Height (M)</th> : <th>Height (Ft)</th>} 
      <th>Low</th>
      {heightType ? <th>Height (M)</th> : <th>Height (Ft)</th>} 
      <th>Low</th>
      {heightType ? <th>Height (M)</th> : <th>Height (Ft)</th>} 
		</tr>

    {data.map(tide =>
    <tr key={tide.date} className={tide.today ? "tideToday" : tide.weekend ? "tideWeekend" : "" }>
			<td>{tide.date}</td>
      <td className="rules">{tide.highTime1}</td>
      {heightType ? <td>{tide.highHeight1}</td> : <td>{tide.highHeightinFeet1}</td>}
      <td className="rules">{tide.highTime2}</td>
      {heightType ? <td>{tide.highHeight2}</td> : <td>{tide.highHeightinFeet2}</td>}
      <td className="rules">{tide.lowTime1}</td>
      {heightType ? <td>{tide.lowHeight1}</td> : <td>{tide.lowHeightinFeet1}</td>}
      <td className="rules">{tide.lowTime2}</td>
      {heightType ? <td>{tide.lowHeight2}</td> : <td>{tide.lowHeightinFeet2}</td>}
      <td className={tide.today ? "tideTodayp" :"tideTodayEmpty"}>&nbsp;</td>
		</tr>
    )}
 
	</tbody>
  </table>
  </div>

  <div css={TidesStyle}>
  {month !== 1 && <button className="tidesbutton" onClick={previousMonth}>Previous Month</button>}
  {month !== 12 && <button className="tidesbuttonright" onClick={nextMonth}>Next Month</button>}
  </div>
  </React.Fragment>
  )
}

export default Tides
